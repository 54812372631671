const LandingPage = () => import(/* webpackChunkName: "main-pages" */ '../pages/main/LandingPage');
const HowToLoginPage = () => import(/* webpackChunkName: "main-pages" */ '../pages/main/HowToLoginPage');
const LoginPage = () => import(/* webpackChunkName: "main-pages" */ '../pages/login/LoginPage');
const LogoutPage = () => import(/* webpackChunkName: "main-pages" */ '../pages/login/LogoutPage');
const SupportPage = () => import(/* webpackChunkName: "support-pages" */ '../pages/support/SupportPage');
const SupportSuccessPage = () => import(/* webpackChunkName: "support-pages" */ '../pages/support/SupportSuccessPage');
const RedirectResolver = () =>
  import(/* webpackChunkName: "redirect-resolver-page" */ '@common/pages/RedirectResolver');

const PrivacyPolicyPage = () =>
  import(/* webpackChunkName: "terms-pages" */ '../pages/privacyPolicy/PrivacyPolicyPage');
const TermsAndConditionsPage = () =>
  import(/* webpackChunkName: "terms-pages" */ '../pages/terms/TermsAndConditionsPage');

const SearchVillagesPage = () =>
  import(/* webpackChunkName: "village-search-pages" */ '../pages/searchVillages/SearchVillagesPage');
const PlaceDetailsPage = () =>
  import(/* webpackChunkName: "village-search-pages" */ '../pages/placeDetails/PlaceDetailsPage');
const SearchOverseasPage = () =>
  import(/* webpackChunkName: "village-search-pages" */ '@common/pages/searchPlacesOverseas/SearchOverseasBasePage');

const SearchSurnamesPage = () =>
  import(/* webpackChunkName: "surname-search-page" */ '@common/pages/searchSurnames/SearchSurnamesPage');
const SurnameDetailsPageContainer = () =>
  import(/* webpackChunkName: "surname-search-page" */ '../pages/surnameDetails/SurnameDetailsPageContainer');
const DialectPage = () => import(/* webpackChunkName: "surname-search-page" */ '../pages/dialects/DialectPage.vue');

const CemeteryDetailsPage = () =>
  import(/* webpackChunkName: "cemetery-pages" */ '@common/pages/cemetery/CemeteryDetailsPage');

const PersonRecordsPage = () =>
  import(/* webpackChunkName: "records-pages" */ '../pages/personRecords/PersonRecordsPage');

const SearchAllRecordsUnifiedPage = () =>
  import(/* webpackChunkName: "records-pages" */ '../pages/searchAllRecordsUnified/SearchAllRecordsUnifiedPage');
const SourceViewerPage = () => import(/* webpackChunkName: "book-pages" */ '../pages/sourceViewer/SourceViewerPage');
const SourceDetailsPage = () => import(/* webpackChunkName: "book-pages" */ '../pages/sourceDetails/SourceDetailsPage');
const ZupuDetailsPage = () =>
  import(/* webpackChunkName: "zupu-search-pages" */ '../pages/zupuDetails/ZupuDetailsPage');
const CollectionDetailsPage = () =>
  import(/* webpackChunkName: "records-pages" */ '../pages/sourceCollectionDetails/CollectionDetailsPage');

const WikiHomePage = () => import(/* webpackChunkName: "wiki-pages" */ '../pages/wiki/article/WikiHomePage');
const WikiArticlePage = () => import(/* webpackChunkName: "wiki-pages" */ '../pages/wiki/article/WikiArticlePage');
const WikiCategoryPage = () => import(/* webpackChunkName: "wiki-pages" */ '../pages/wiki/article/WikiCategoryPage');
const WikiSearchPage = () => import(/* webpackChunkName: "wiki-pages" */ '../pages/wiki/search/WikiSearchPage');
const WikiEventsPage = () => import(/* webpackChunkName: "wiki-pages" */ '@common/pages/wiki/WikiEventsPage');

const InstitutionsIndexingPage = () =>
  import(/* webpackChunkName: "main-pages" */ '@common/pages/institutions/InstitutionsIndexingPage');
const PageNotFound = () => import('../pages/PageNotFound');
const consts = require('./consts');

const routes = [
  {
    path: '/',
    name: consts.LANDING_PAGE,
    component: LandingPage,
    meta: {hideMainMenu: true},
  },
  {
    path: '/how-to-login',
    name: 'how-to-login',
    component: HowToLoginPage,
    meta: {isOpenRoute: true},
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    meta: {isOpenRoute: true},
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutPage,
    meta: {isOpenRoute: true},
  },
  {
    path: '/support',
    name: 'support',
    component: SupportPage,
    meta: {isOpenRoute: true},
  },
  {
    path: '/support/success',
    name: 'support-success',
    component: SupportSuccessPage,
    meta: {isOpenRoute: true},
  },
  {
    path: '/search/sources',
    name: 'search-sources',
    redirect: to => ({name: 'search-all-records', query: {tab: 'sources', ...to.query, results: 'true'}}),
  },
  {
    path: '/search/records',
    name: 'search-all-records',
    component: SearchAllRecordsUnifiedPage,
    meta: {
      showMcrFooter: true,
      displayGlossary: true,
      rememberScrollPosition: true,
      getRouteName: route => (route.query.category_id ? 'search-category-records' : 'search-all-records'),
      getIdentifier: route => route.query.category_id || '',
    },
  },
  {
    path: '/search/burial-records',
    name: 'search-burial-records',
    redirect: to => ({
      name: 'search-all-records',
      query: {tab: 'indexed-records', ...to.query, category_id: 'birth-marriage-death'},
    }),
  },
  {
    path: '/search/books-records',
    name: 'search-books-records',
    redirect: to => ({
      name: 'search-all-records',
      query: {tab: 'indexed-records', ...to.query, category_id: 'associations-directories'},
    }),
  },
  {
    path: '/search/immigration-records',
    name: 'search-immigration-records',
    redirect: to => ({
      name: 'search-all-records',
      query: {tab: 'indexed-records', ...to.query, category_id: 'immigration'},
    }),
  },
  {
    path: '/searchTool/village',
    redirect: {name: 'search-tool-village'},
  },
  {
    path: '/search/villages',
    name: 'search-tool-village',
    component: SearchVillagesPage,
    meta: {showMcrFooter: true, displayGlossary: true},
  },
  {
    path: '/search/overseas',
    name: 'search-overseas',
    component: SearchOverseasPage,
    meta: {showMcrFooter: true, displayGlossary: true},
  },
  {
    path: '/villages/:villageId/:slug?',
    name: 'search-tool-village-detail',
    redirect: to => ({name: 'place-detail', params: {placeId: to.params.villageId, slug: to.params.slug}}),
  },
  {
    path: '/migration-destination/:placeId/:slug?',
    name: 'migration-destination-detail',
    redirect: to => ({name: 'place-detail', params: {placeId: to.params.placeId, slug: to.params.slug}}),
  },
  {
    path: '/places/:placeId/:slug?',
    name: 'place-detail',
    component: PlaceDetailsPage,
    meta: {showMcrFooter: true, getIdentifier: route => route.params.placeId},
  },
  {
    path: '/searchTool/surname',
    redirect: {name: 'search-tool-surname'},
  },
  {
    path: '/search/surnames',
    name: 'search-tool-surname',
    component: SearchSurnamesPage,
    meta: {displayGlossary: true},
  },
  {
    path: '/surnames/detail',
    name: 'search-tool-surname-detail',
    component: SurnameDetailsPageContainer,
    meta: {
      displayGlossary: true,
      showMcrFooter: true,
      getIdentifier: route => route.query.word,
      getRouteName: route => {
        const isChineseText = route.query.word && route.query.word.match(/[\u3400-\u9FBF]/gi);
        return isChineseText ? route.name : 'surname-variant-detail';
      },
    },
  },
  {
    path: '/dialects/:slug',
    name: 'dialect-detail',
    component: DialectPage,
    meta: {showMcrFooter: true},
  },
  {
    path: '/cemeteries/:id/:slug?',
    name: 'cemetery-detail',
    component: CemeteryDetailsPage,
    meta: {showMcrFooter: true, getIdentifier: route => route.params.id},
  },
  {
    path: '/source/:sourceId',
    name: 'source-detail',
    component: SourceDetailsPage,
    meta: {getIdentifier: route => route.params.sourceId, showMcrFooter: true},
  },
  {
    path: '/source/:sourceId/viewer',
    name: 'source-viewer',
    component: SourceViewerPage,
    meta: {getIdentifier: route => route.params.sourceId, hideMainMenu: true, customPageOpenTrack: true},
  },
  {
    path: '/source-collections/:id',
    name: 'collection-detail',
    component: CollectionDetailsPage,
    meta: {getIdentifier: route => route.params.id, showMcrFooter: true},
  },
  {
    path: '/searchTool/zupu',
    redirect: {name: 'search-tool-zupu'},
  },
  {
    path: '/search/zupus',
    name: 'search-tool-zupu',
    redirect: to => ({name: 'search-all-records', query: {tab: 'full-text', ...to.query, category_id: 'zupus'}}),
  },
  {
    path: '/searchTool/zupu/detail/:zupuId',
    redirect: to => ({name: 'search-tool-zupu-detail', params: {zupuId: to.params.zupuId}}),
  },
  {
    path: '/zupus/:zupuId',
    name: 'search-tool-zupu-detail',
    component: ZupuDetailsPage,
    meta: {showMcrFooter: true, getIdentifier: route => route.params.zupuId},
  },
  {
    path: '/zupus/:zupuId/viewer',
    name: 'zupu-viewer',
    component: RedirectResolver,
    meta: {getRouteName: route => '', hideMainMenu: true, customPageOpenTrack: true},
  },
  {
    path: '/person-records/:code/:slug?',
    name: 'person-records-detail',
    component: PersonRecordsPage,
    meta: {
      showMcrFooter: true,
      getIdentifier: route => route.params.code,
      displayGlossary: true,
    },
  },
  {
    path: '/services/institutions/indexing',
    name: 'institutions-indexing',
    component: InstitutionsIndexingPage,
    meta: {displayGlossary: true, isOpenRoute: true},
  },
  {
    path: '/wiki',
    name: 'wiki-index',
    component: WikiHomePage,
    meta: {showMcrFooter: true},
  },
  {
    path: '/wiki/article/:slug',
    name: 'wiki-article',
    component: WikiArticlePage,
    meta: {showMcrFooter: true, getIdentifier: route => route.params.slug},
  },
  {
    path: '/wiki/search',
    name: 'wiki-search',
    component: WikiSearchPage,
    meta: {showMcrFooter: true},
  },
  {
    path: '/events',
    name: 'events',
    component: WikiEventsPage,
    meta: {showMcrFooter: true},
  },
  {
    path: '/wiki/category/:slug',
    name: 'wiki-category',
    component: WikiCategoryPage,
    meta: {showMcrFooter: true, getIdentifier: route => route.params.slug},
  },
  {
    path: '/privacy',
    component: PrivacyPolicyPage,
    name: 'privacy-policy',
    meta: {showMcrFooter: true, isOpenRoute: true},
  },
  {
    path: '/terms',
    component: TermsAndConditionsPage,
    name: 'terms',
    meta: {showMcrFooter: true, isOpenRoute: true},
  },
  {path: '*', name: 'not-found', component: PageNotFound},
];

// used in webpack config
module.exports = {default: routes};
