import {NetworkAPIVersion, NetworkParamType} from './network.utils';

export default {
  details: {
    url: 'familytree/{id}/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    params: {
      start_person_id: [NetworkParamType.number],
    },
  },
  getDrawn: {
    url: 'familytree/{id}/draw/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    autoCancel: true,
    params: {
      start_person_id: [NetworkParamType.number],
      control_data: [NetworkParamType.array], // controller_id, controller_type, controller_state, action,
    },
  },
  getDrawnDiff: {
    url: 'familytree/{id}/diff/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    autoCancel: true,
    params: {
      start_person_id: [NetworkParamType.number],
      map_hash: [NetworkParamType.string],
      trigger: [NetworkParamType.string],
      with_ghosts: [NetworkParamType.number], // boolean 1 or 0
      control_data: [NetworkParamType.array], // controller_id, controller_type, controller_state, action,
    },
  },
  setExpandControl: {
    url: 'familytree/{id}/expand-control/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    autoCancel: true,
    params: {
      data_list: [NetworkParamType.array], // controller_id, controller_type, controller_state, action,
      start_person_id: [NetworkParamType.string],
    },
  },
  create: {
    url: 'familytree/create/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    params: {
      start_person: [NetworkParamType.number],
    },
  },
  importGedcom: {
    url: 'api/people/import/gedcom/',
    apiVersion: NetworkAPIVersion.noVersion,
    method: 'post',
    multipart: true,
    params: {
      file: [NetworkParamType.file],
      tree_id: [NetworkParamType.number],
    },
  },
  importStatus: {
    url: 'api/people/import/status/',
    apiVersion: NetworkAPIVersion.noVersion,
    method: 'get',
    params: {
      importKey: [NetworkParamType.string],
    },
  },
  delete: {
    url: 'familytree/{id}/delete/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'delete',
  },
  addMembers: {
    url: 'familytree/{id}/add-members/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    params: {
      emails: [NetworkParamType.array],
    },
  },
  editMember: {
    url: 'familytree/{id}/edit-member/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    params: {
      email: [NetworkParamType.string],
      is_editor: [NetworkParamType.boolean],
    },
  },
  deleteMember: {
    url: 'familytree/{id}/delete-member/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    params: {
      email: [NetworkParamType.string],
    },
  },
  getMap: {
    url: 'api/people/tree/image/{id}/',
    apiVersion: NetworkAPIVersion.noVersion,
    method: 'get',
    responseType: 'arraybuffer',
    params: {
      scale: [NetworkParamType.string],
      color: [NetworkParamType.string],
      start_person_id: [NetworkParamType.string],
      map_hash: [NetworkParamType.string],
    },
  },
  exportTree: {
    url: 'api/people/tree/export/{id}/',
    apiVersion: NetworkAPIVersion.noVersion,
    method: 'get',
    params: {
      format: [NetworkParamType.string],
      include_images: [NetworkParamType.string],
      include_library: [NetworkParamType.string],
      status_key: [NetworkParamType.string],
    },
  },
  list: {
    url: 'familytree/list/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    params: {
      fields: [NetworkParamType.string],
    },
  },
  update: {
    url: 'familytree/{id}/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'patch',
    params: {
      is_public: [NetworkParamType.boolean],
      name: [NetworkParamType.string],
      home_person_id: [NetworkParamType.number],
    },
  },
  searchPersons: {
    url: 'familytree/{id}/search/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    autoCancel: true,
    params: {
      query: [NetworkParamType.string],
    },
  },
  searchRelationNames: {
    url: 'familytree/{id}/relation-names/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    autoCancel: true,
    params: {
      start_person_id: [NetworkParamType.string],
    },
  },
  searchPersonsInAllTrees: {
    url: 'familytree/search-persons/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    autoCancel: true,
    params: {
      query: [NetworkParamType.string],
    },
  },
  searchClansInAllTrees: {
    url: 'familytree/search-clans/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    autoCancel: true,
    params: {
      query: [NetworkParamType.string],
    },
  },
  getMostTaggedLocations: {
    url: 'familytree/{id}/locations/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    autoCancel: true,
    params: {
      q: [NetworkParamType.string],
    },
  },
  getChineseSurnames: {
    url: 'familytree/{id}/chinese-surnames/',
    apiVersion: NetworkAPIVersion.v1_version,
    autoCancel: true,
    method: 'get',
  },
  geTreeClanMeta: {
    url: 'familytree/{id}/surname/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
    params: {
      q: [NetworkParamType.string],
    },
  },
  getTreeOnboardingData: {
    url: 'familytree/onboarding/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'get',
  },
  updateTreeOnboardingData: {
    url: 'familytree/onboarding/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'post',
    params: {
      is_onboarded: [NetworkParamType.boolean],
      onboarding_step: [NetworkParamType.string],
    },
  },
  deleteParentRelationship: {
    url: 'familytree/{id}/relationships/parent/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'delete',
    autoCancel: true,
    params: {
      parent_id: [NetworkParamType.number],
      child_id: [NetworkParamType.number],
      children_ids: [NetworkParamType.array],
    },
  },
  deleteSpouseRelationship: {
    url: 'familytree/{id}/relationships/spouse/',
    apiVersion: NetworkAPIVersion.v1_version,
    method: 'delete',
    autoCancel: true,
    params: {
      person_id: [NetworkParamType.number],
      spouse_id: [NetworkParamType.number],
    },
  },
};
