import {getCookie, setCookie} from '@common/utils/cookies';
import {isChineseText} from '@common/utils/utils';
import moment from 'moment';

import {disableTracking} from './utils.analytics';

const setOnceCache = [];

function getQueryLanguage(filters) {
  const queries = [filters.first_name, filters.surname, filters.q, filters.last_name].filter(value => !!value);
  if (!queries.length) {
    return;
  }
  if (queries.every(value => isChineseText(value, 100))) {
    return 'Chinese';
  }
  return 'English';
}

const AnalyticsAmplitudeHandler = (function () {
  const instance = {};

  instance.project = null;

  instance.trackPageView = (pageName, identifier = '', props = null) => {
    instance.setOnce('Initial landing page', pageName);
    props = props || {};
    instance.logEvent('Open page', {'page-name': pageName, identifier, ...props});
  };
  instance.trackClickRegisterButton = () => {
    instance.logEvent('Click register button');
  };
  instance.trackPreRegisterAction = (initiator, pageName, label) => {
    instance.logEvent('Pre-register action', {initiator, 'page-name': pageName, label});
  };
  instance.trackPreRegisterActionLinkClick = (pageName, label) => {
    instance.trackPreRegisterAction('Link click', pageName, label);
  };
  instance.trackPaywallModalOpenAction = version => {
    instance.logEvent('Paywall Modal Open', {version: version});
  };
  instance.trackHitPaywallAction = (action, type, path, pageName) => {
    const params = {'action-attempted': action, 'paywall-type': type, 'paywall-path': path, 'page-name': pageName};
    instance.logEvent('Hit Paywall', params);
  };
  instance.trackStartStandardSubscriptionClick = () => {
    instance.logEvent('Standard subscription: click start button');
  };
  instance.trackCancelStandardSubscriptionClick = pageName => {
    instance.logEvent('Standard subscription: click cancel button', {'page-name': pageName});
  };
  instance.trackStandardSubscriptionCancelSubmit = (reason, commentLeft) => {
    instance.logEvent('Standard Subscription: cancel submit', {reason: reason, 'comment-left': commentLeft});
  };
  instance.trackUpgradeStandardSubscriptionClick = () => {
    instance.logEvent('Standard subscription: click upgrade button');
  };
  instance.trackAdvancedSearchClick = (pageName, label) => {
    instance.logEvent('Advanced search click ', {'page-name': pageName, label});
  };
  instance.trackClickButton = (buttonLabel, pageName, identifier) => {
    instance.logEvent('Click button', {label: buttonLabel, 'page-name': pageName, identifier});
  };
  instance.trackClicGenericCtaBlock = (buttonLabel, tagline, pageName, identifier) => {
    instance.logEvent('Click generic CTA block', {
      'button-label': buttonLabel,
      tagline,
      'page-name': pageName,
      identifier,
    });
  };
  instance.trackClickMenuItem = (label, pageName, identifier) => {
    instance.logEvent('Click menu item', {label, 'page-name': pageName, identifier});
  };
  instance.trackClickContactButton = (buttonLabel, isModal, pageName, isCall = false) => {
    instance.logEvent('Click contact button', {
      label: buttonLabel,
      'form-modal': isModal,
      'page-name': pageName,
      'is-call': isCall,
    });
  };
  instance.trackClickPaymentButton = (buttonLabel, productId, pageName) => {
    instance.logEvent('Click payment button', {label: buttonLabel, 'page-name': pageName, product: productId});
  };
  instance.trackClickDiscordLink = (linkLocation, pageName) => {
    instance.logEvent('Click Discord link', {'link-location': linkLocation, 'page-name': pageName});
  };
  instance.trackSurnameSearchSubmit = (filters, resultsCount) => {
    const lang = getQueryLanguage(filters);
    instance.logEvent('Search surname: Submit', {filters, 'results-count': resultsCount, 'query-language': lang});
  };
  instance.trackSearchTabClick = (tab, pageName) => {
    instance.logEvent('Search form: Click tab', {'tab-type': tab, 'page-name': pageName});
  };
  instance.trackSearchFilterClick = (type, value, count, pageName) => {
    const args = {'filter-type': type, 'filter-value': value, 'filter-result-count': count, 'page-name': pageName};
    instance.logEvent('Search results: Click filter', args);
  };
  instance.trackVillageSearchSubmit = (filters, resultsCount) => {
    const lang = getQueryLanguage(filters);
    instance.logEvent('Search village: Submit', {filters, 'results-count': resultsCount, 'query-language': lang});
  };
  instance.trackAncestorSearchClickSearchButton = pageName => {
    instance.logEvent('Search ancestor: Click search button', {'page-name': pageName});
  };
  instance.trackWikiSearchSubmit = (filters, resultsCount) => {
    const words = filters.q.split(' ');
    instance.logEvent('Search wiki: Submit ', {filters, words, 'results-count': resultsCount});
  };
  instance.trackAncestorSearchSubmit = (filters, resultsCount) => {
    instance.logEvent('Search ancestor: Submit', {filters, 'results-count': resultsCount});
  };
  instance.trackAllRecordsSearchSubmit = (filters, resultsCount, type) => {
    const lang = getQueryLanguage(filters);
    instance.logEvent('Search all: Submit', {filters, 'results-count': resultsCount, 'query-language': lang, type});
  };
  instance.trackAncestorSearchError = (filters, errorObject) => {
    const errorText = JSON.stringify(errorObject);
    instance.logEvent('Search ancestor: Error', {filters, 'error-text': errorText});
  };
  instance.trackAncestorSearchSelectSurname = (valueId, valueText) => {
    instance.logEvent('Search ancestor: Select surname', {'value-id': valueId, 'value-text': valueText});
  };
  instance.trackAncestorSearchSelectPlace = (valueId, valueText) => {
    instance.logEvent('Search ancestor: Select place', {'value-id': valueId, 'value-text': valueText});
  };
  instance.trackZupuGetAccessButton = pageName => {
    instance.logEvent('Click zupu Get Access button', {'page-name': pageName});
  };
  instance.newsletterSignup = pageName => {
    instance.logEvent('Newsletter Signup', {'page-name': pageName});
  };
  instance.trackViewerSearchSubmit = (q, resultsCount) => {
    if (q) {
      instance.logEvent('Viewer: Search record submit', {q: q, 'results-count': resultsCount});
    }
  };
  instance.trackViewerUseDictionary = () => {
    instance.logEvent('Viewer: Use dictionary');
  };
  instance.trackViewerNavigatePage = (type, pageNumber) => {
    instance.logEvent('Viewer: Navigate page', {type, 'page-number': pageNumber});
  };
  instance.trackZupuViewerGetHelp = (pk, title) => {
    instance.logEvent('Viewer: Get help', {'zupu-pk': pk, 'zupu-title': title});
  };
  instance.trackSourceViewerGetHelp = (pk, title) => {
    instance.logEvent('Viewer: Get help', {'source-pk': pk, 'source-title': title});
  };
  instance.trackSurnameGetStartedClick = (buttonLabel, surname) => {
    instance.logEvent('Surname: Click get started', {label: buttonLabel, surname});
  };
  instance.trackSurnameGoToTreeClick = surname => {
    instance.logEvent('Surname: Click Go to My tree', {surname});
  };
  instance.trackSurnameSaveToTreeClick = surname => {
    instance.logEvent('Surname: Click Save to My tree', {surname});
  };
  instance.trackSurnameRecordsSearchSubmit = (surname, surnameType) => {
    instance.logEvent('Surname: Records search submit', {surname, 'surname-type': surnameType});
  };
  instance.trackPlayVideoEvent = (label, pageName) => {
    instance.logEvent('Play video', {label: label, 'page-name': pageName});
  };
  instance.trackDownloadGenealogyGuideEvent = () => {
    instance.logEvent('Download Genealogy Guide');
  };
  instance.trackClickShareLinkEvent = (pageName, params = {}) => {
    instance.logEvent('Click share link', {'page-name': pageName, ...params});
  };
  instance.trackClickSocialShareLinkEvent = (pageName, platform, params = {}) => {
    instance.logEvent('Click social share button', {'page-name': pageName, platform, ...params});
  };
  instance.trackClickSaveRecordEvent = (pageName, country, sourceType) => {
    instance.logEvent('Click save record button', {'page-name': pageName, country, 'source-type': sourceType});
  };
  instance.trackSaveRecordEvent = (pageName, inbox, country, sourceType) => {
    instance.logEvent('Save record', {'page-name': pageName, inbox, country, 'source-type': sourceType});
  };
  instance.trackTreeOpenQuickEditEvent = personId => {
    instance.logEvent('Family tree: Open quick edit', {'tree-person-id': personId});
  };
  instance.trackTreeOpenQuickViewEvent = personId => {
    instance.logEvent('Family tree: Open quickview', {'tree-person-id': personId});
  };
  instance.trackTreeQuickMenuActionClickEvent = (label, hotkey) => {
    instance.logEvent('Tree: Quick view menu action', {label, hotkey});
  };
  instance.trackTreeEditDetailsEvent = personId => {
    instance.logEvent('Family tree: Edit details', {'tree-person-id': personId});
  };
  instance.trackTreeOpenProfileTabEvent = (personId, tab) => {
    instance.logEvent('Family tree: Open profile tab', {'tree-person-id': personId, tab: tab});
  };
  instance.trackTreeOpenShortcutsModalEvent = () => {
    instance.logEvent('Family tree: Open shortcuts modal');
  };
  instance.trackTreeClickGhostCardEvent = () => {
    instance.logEvent('Family tree: Click ghost card');
  };
  instance.trackTreeAddSiblingWithoutParentEvent = () => {
    instance.logEvent('Family tree: Add sibling without parent');
  };
  instance.trackTreeTutorialStartEvent = () => {
    instance.logEventOnce('Tree: Tutorial Start');
  };
  instance.trackTreeFirstDragToPanEvent = () => {
    instance.logEventOnce('Tree: First drag to pan');
  };
  instance.trackTreeFirstZoomEvent = () => {
    instance.logEventOnce('Tree: First zoom');
  };
  instance.trackClickLanguageSelectorEvent = (pageName, identifier, tab, language) => {
    const mapping = {en: 'English', cn: 'Chinese'};
    const languageText = mapping[language] || language;
    const args = {'page-name': pageName, tab: tab, 'language-selected': languageText, identifier: identifier};
    instance.logEvent('Click language selector', args);
  };
  instance.trackHintClickButtonEvent = () => {
    instance.logEvent('Click quick view hint button');
  };
  instance.trackHintClickAcceptEvent = () => {
    instance.logEvent('Click Accept Hint button');
  };
  instance.trackHintClickIgnoreEvent = () => {
    instance.logEvent('Click Ignore Hint button');
  };
  instance.trackHintsFilterClickEvent = (filter, pageName) => {
    instance.logEvent('Click Hints filter', {filter, 'page-name': pageName});
  };
  instance.trackHintClickSaveInfoEvent = () => {
    instance.logEvent('Click Save Information button');
  };
  instance.trackHintSaveDataClickBackEvent = () => {
    instance.logEvent('Hint Save Data: Click back');
  };
  instance.trackClickSelectToOcrEvent = pageName => {
    instance.logEvent('Click Select to OCR button', {'page-name': pageName});
  };
  instance.trackSelectAreaOcrResultsEvent = (ocrType, textDetected, pageName) => {
    instance.logEvent('Selected Area OCR: OCR results', {
      'ocr-type': ocrType,
      'text-detected': textDetected,
      'page-name': pageName,
    });
  };
  instance.trackGedcomFileSelectedEvent = () => {
    instance.logEvent('Import GEDCOM: File selected');
  };
  instance.trackGedcomUploadClickedEvent = () => {
    instance.logEvent('Import GEDCOM: Click upload button');
  };
  instance.trackGedcomImportFailedEvent = errorType => {
    instance.logEvent('Import GEDCOM: Import failed', {'error-type': errorType});
  };
  instance.trackGedcomImportSuccessEvent = (personsCount, filesCount, warningsCount, importSecs) => {
    const params = {
      'persons-count': personsCount,
      'files-count': filesCount,
      'warnings-count': warningsCount,
      'import-time': importSecs,
    };
    instance.logEvent('Import GEDCOM: Import successful', params);
  };
  instance.trackOnboardingNextStepEvent = (step, fieldsFilledCount) => {
    let params = {step};
    if (fieldsFilledCount || fieldsFilledCount === 0) {
      params['fields-filled'] = fieldsFilledCount;
    }
    instance.logEvent('Full Screen Onboarding: Next Step', params);
  };
  instance.trackOnboardingSkipStepEvent = step => {
    instance.logEvent('Full Screen Onboarding: Skip Step', {step});
  };
  instance.trackOnboardingClickPlaceShortcutEvent = (level, name, placeId) => {
    instance.logEvent('Full Screen Onboarding: Click place shortcut', {
      'place-level': level,
      'place-name': name,
      'place-id': placeId,
    });
  };
  instance.trackClickLoginThroughInstitutionEvent = () => {
    instance.logEvent('Click log in through your institution button');
  };
  instance.trackClickWikiPopupEvent = (pageName, identifier, slug) => {
    instance.logEvent('Click wiki popup link', {'page-name': pageName, identifier, 'wiki-slug': slug});
  };

  instance.setUserFeaturesProperty = enabledFeaturesNames => {
    instance.setUserProperties({Features: enabledFeaturesNames});
  };
  instance.setUserFullNameProperties = (firstName, lastName) => {
    instance.setUserProperties({'First name': firstName, 'Last name': lastName});
  };
  instance.updateUserLastSeenDate = () => {
    if (disableTracking() || !instance.project || typeof moment === 'undefined') {
      return;
    }
    const userId = instance.getUserId();
    const cookieName = `user-last-seen-date-${userId || ''}`;
    let userLastSeen = getCookie(cookieName);
    if (!userLastSeen) {
      userLastSeen = moment().format('YYYY-MM-DDTHH:mm:ss');
      instance.setUserProperties({'Last seen date': userLastSeen});
      setCookie(cookieName, userLastSeen, 12);
    }
  };

  instance._call = (methodName, ...args) => {
    if (instance.project) {
      return instance.project[methodName](...args);
    }
  };

  instance.init = apiKey => {
    if (!apiKey || instance.project || disableTracking()) {
      return;
    }
    const amplitude = require('amplitude-js');
    instance.project = amplitude.getInstance();
    instance.project.init(apiKey, null, {
      deferInitialization: true,
      saveEvents: true,
      includeUtm: true,
      includeReferrer: true,
      includeGclid: true,
      saveParamsReferrerOncePerSession: false,
      logAttributionCapturedEvent: true,
    });
  };
  instance.disable = () => {
    instance.project = null;
  };

  instance.setUserId = (...args) => {
    instance._call('setUserId', ...args);
  };
  instance.getUserId = () => {
    // is empty if anon user
    if (instance.project && instance.project.options) {
      return instance.project.options.userId;
    }
  };
  instance.regenerateDeviceId = (...args) => {
    instance._call('regenerateDeviceId', ...args);
  };
  instance.mustEnableQueue = eventArgs => {
    if (
      instance.project &&
      instance.project._initializationDeferred &&
      instance.project._q &&
      instance.project._q.length
    ) {
      const events = instance.project._q.filter(event => event && event.length >= 3 && event[0] === 'logEvent');
      const isEventSuspicious = eventArgs.length >= 2 && eventArgs[0] === 'Open page';
      return events.length >= 1 || !isEventSuspicious;
    }
  };
  instance.logEvent = (...args) => {
    try {
      if (instance.mustEnableQueue(args)) {
        /* one enable tracking doesn't work, it only enables after second enable tracking */
        instance.project.enableTracking();
        instance.project.enableTracking();
      }
    } catch {
      instance.project.enableTracking();
      instance.project.enableTracking();
    }
    instance._call('logEvent', ...args);
  };
  instance.loggedEvents = new Set();
  instance.logEventOnce = (...args) => {
    if (!instance.loggedEvents.has(args[0])) {
      instance._call('logEvent', ...args);
      instance.loggedEvents.add(args[0]);
    }
  };
  instance.getSessionId = () => {
    return instance._call('getSessionId');
  };
  instance.setUserProperties = properties => {
    instance._call('setUserProperties', properties);
  };
  instance.setOnce = (property, value) => {
    if (setOnceCache.includes(property)) {
      return;
    }
    const amplitude = require('amplitude-js');
    const identify = new amplitude.Identify().setOnce(property, value);
    instance._call('identify', identify);
    setOnceCache.push(property);
  };

  return instance;
})();

export default AnalyticsAmplitudeHandler;
