// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import LoadingIndicator from '@common/elements/loading/loadingIndicator';
import network from '@common/network/network';
import {breakpoints} from '@common/plugins/breakpoints';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import AnalyticsHubspotHandler from '@common/utils/analytics/analytics.hubspot';
import {getRoutePageIdentifier, getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {TAB_ID_INDEXED_RECORDS} from '@common/utils/consts.search';
import '@common/utils/directives/vue-directives';
import {initHotjar} from '@common/utils/script.loader';
import {getAsset} from '@common/utils/utils';
import store from '@library/store';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import pick from 'lodash/pick';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import Vue from 'vue';
import VModal from 'vue-js-modal';
import 'vue-lazy-youtube-video/dist/style.css';
import VueMeta from 'vue-meta';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import panZoom from 'vue-panzoom';
import Router from 'vue-router';
import VueScrollTo from 'vue-scrollto';
import Toasted from 'vue-toasted';
import {mapGetters} from 'vuex';

import App from './App';
import router from './router';

const consts = require('./router/consts');

Vue.use(Router);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});
Vue.use(VModal, {dynamic: true, injectModalsContainer: true});
Vue.use(FloatingVue, {
  distance: 10,
  arrowPadding: 10,
  themes: {
    tooltip: {
      html: true,
    },
    'interactive-tooltip': {
      $extend: 'tooltip',
      $resetCss: true,
      triggers: [],
      autoHide: false,
    },
    'tooltip-info': {
      $extend: 'tooltip',
      $resetCss: true,
    },
  },
});
Vue.use(Toasted, {
  position: 'top-center',
  duration: 3000,
  keepOnHover: true,
  router,
});
Vue.use(panZoom);
Vue.use(VueScrollTo);
Vue.use(breakpoints);

Vue.config.productionTip = false;

Vue.prototype.network = network;
Vue.prototype.$getAsset = getAsset;

if (!window['__PRERENDER_INJECTED']) {
  AnalyticsAmplitudeHandler.init(process.env.VUE_APP_AMPLITUDE_ID);
  if (process.env.VUE_APP_HOTJAR_ID) {
    initHotjar();
  }
}

Vue.component('mcr-loading-indicator', LoadingIndicator);

/* eslint-disable no-new */
const root = new Vue({
  router,
  store,
  components: {App},
  directives: {App},
  template: '<App/>',
  destroyed() {
    window.removeEventListener('resize', this.$calculateWidth);
  },
  created() {
    window.addEventListener('resize', this.$calculateWidth);
    window.addEventListener('load', this.$calculateWidth);
    this.$calculateWidth();
    if (this.userIsLoggedInState) {
      AnalyticsAmplitudeHandler.setUserId(this.$store.getters.userEmailState);
      this.$store.dispatch('getNotificationsAction');
    }
    AnalyticsAmplitudeHandler.updateUserLastSeenDate();
  },
  computed: {
    ...mapGetters([
      'userIsLoggedInState',
      'userEmailState',
      'userFirstNameState',
      'userLastNameState',
      'userFullNameState',
    ]),
  },
  methods: {
    $calculateWidth() {
      var scrollDiv = document.createElement('div');
      scrollDiv.className = 'scrollbar-measure';
      scrollDiv.style.width = '100px';
      scrollDiv.style.height = '100px';
      scrollDiv.style.overflow = 'scroll';
      scrollDiv.style.position = 'absolute';
      scrollDiv.style.top = '-9999px';
      document.body.appendChild(scrollDiv);
      var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      document.body.removeChild(scrollDiv);

      // window.innerWidth works weirdly on ios
      this.$store.commit('setWindowWidthState', document.documentElement.clientWidth + scrollbarWidth);
    },
  },
});

document.addEventListener('DOMContentLoaded', function () {
  root.$mount('#app');
});

function resolveRouteOnAuth(to, next) {
  if (to.name === consts.LANDING_PAGE) {
    next({name: 'search-all-records', query: {tab: TAB_ID_INDEXED_RECORDS}});
    NProgress.done();
    return;
  }
  next();
  NProgress.done();
}

router.beforeResolve((to, from, next) => {
  if (router.app.$store.getters.ipLoginLoadingState) {
    return false; // cancel navigation
  }

  const userIsLoggedIn = router.app.$store.getters.userIsLoggedInState;
  if (userIsLoggedIn) {
    return resolveRouteOnAuth(to, next);
  }
  /* avoid duplicate ip login when redirect from other pages to the main page */
  const loginAttemptedRecently = new Date().getTime() < router.app.$store.getters.loginCalledDatetimeState + 1000;
  if ((to.name === consts.LANDING_PAGE && loginAttemptedRecently) || to.meta.isOpenRoute) {
    next();
    NProgress.done();
    return;
  }
  router.app.$store
    .dispatch('userIpAddressLoginAction')
    .then(res => {
      AnalyticsAmplitudeHandler.setUserId(res.email);
      resolveRouteOnAuth(to, next);
    })
    .catch(() => {
      NProgress.done();
      if (to.name === consts.LANDING_PAGE && to.query['help-login'] === 'true') {
        next({name: 'how-to-login'});
        return;
      }
      if (to.name === consts.LANDING_PAGE) {
        next();
        return;
      }
      next({name: consts.LANDING_PAGE, query: {access_denied: 'true'}});
    });
});

router.beforeEach((to, from, next) => {
  if (to.name !== from.name) {
    NProgress.start();
  }
  const previousRoute = pick(from, 'fullPath', 'name', 'meta', 'params', 'path', 'query');
  router.app.$store.commit('setPreviousRouteState', previousRoute);

  router.app.$store.dispatch('hideGalleryAction');
  router.app.$store.commit('setBodyForbidScrollState', false);
  // remove this when update vue-js-modal to v2.0 and use $modal.hideAll();
  const modalsContainer = document.getElementById('modals-container');
  while (modalsContainer && modalsContainer.firstChild) {
    modalsContainer.removeChild(modalsContainer.firstChild);
  }
  next();
});

function forbidStopRequests(fromName, toName) {
  if (fromName === toName) {
    return true;
  }
  for (let routes of consts.RELATED_ROUTES) {
    if (routes.includes(fromName) && routes.includes(toName)) {
      return true;
    }
  }
}

router.afterEach((to, from) => {
  NProgress.done();

  setTimeout(() => {
    // main menu tooltip is misplaced when browser scrollbar appears or disappears between routes
    window.dispatchEvent(new Event('resize'));
  }, 0);

  if (from.name && !forbidStopRequests(from.name, to.name)) {
    // hack to stop browser from finishing loading of images (and all other requests) on route change
    try {
      window.stop(); // does not work in IE11
    } catch (exception) {
      document.execCommand('Stop');
    }
  }

  if (window.ga) {
    ga('set', 'page', to.path);
    ga('send', 'pageview');
  }

  const toName = getRoutePageName(to);
  const fromName = getRoutePageName(from);
  const toIdentifier = getRoutePageIdentifier(to);
  const fromIdentifier = getRoutePageIdentifier(from);
  if (toName && (fromName !== toName || toIdentifier !== fromIdentifier)) {
    if (
      router.app.$store.getters.userIsLoggedInState &&
      router.app.$store.getters.nextRefreshTimeState <= new Date().getTime()
    ) {
      router.app.$store
        .dispatch('refreshMyInformationAction')
        .then(response => {
          if (!response.is_subscribed) {
            router.app.$store.commit('clearUserState');
            router.push({name: consts.LANDING_PAGE, query: {access_denied: 'true'}});
            return;
          }
          router.app.$store.commit(
            'setNextRefreshTimeState',
            new Date().getTime() + parseInt(process.env.VUE_APP_REFRESH_TOKEN_TIMEOUT)
          );
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            router.app.$store.commit('clearUserState');
            router.push({name: consts.LANDING_PAGE, query: {access_denied: 'true'}});
          }
        });
    }
    router.app.$store.dispatch('fetchFeatureSwitchesAction').then(switches => {
      if (!to.meta.customPageOpenTrack) {
        AnalyticsAmplitudeHandler.trackPageView(toName, toIdentifier);
      }
    });

    AnalyticsHubspotHandler.trackPageView(to.path);
  }
});
